import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DynamicLabel from '../../../../components/Labels/Dynamic.label';
import { createDateFormatWithTime, getTimeFromMins } from '../../../../helpers/date.helper';
import { ExamService } from '../../../../Services/examServices';
import { IExam } from '../../../../interfaces/IExam'
import '../../../../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDotCircle, faPlay, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import LiveExam from '../../../../Services/liveexam.services';
import { errorResponseHelper } from '../../../../helpers/request.helper';
import BooleanLabel from '../../../../components/Labels/Boolean.label';
import moment from 'moment';
import prettyMilliseconds from 'pretty-ms';
import { CheckCircleOutlineOutlined, Spa } from '@material-ui/icons';
import { CgCross } from 'react-icons/cg';
import { FaDownload, FaRegCircleXmark } from "react-icons/fa6";
interface IOnging {
    exams: IExam[]
}

export default function Ongoing(props: IOnging) {
    const navigate = useNavigate();
    const [startExamId, setStartExamId] = useState('')


    const generateTokenForLiveExam = async (examId: string, mappingId: string) => {
        await LiveExam.generateTokenForLiveExam(examId, mappingId).then((res) => {
            if (res.status === 200) {
                sessionStorage.setItem("liveToken", res.data.data.token);
                toast.success("Exam CountDown Has begun");
                navigate('/live/exam-live', { state: { examId: examId, mappingId: mappingId } });
            }
        }).catch(e => {
            errorResponseHelper(e)
        })
    }

    const startExam = async (data: any, id2: any) => {

        await ExamService.startExamId(data)
            .then(async (res) => {
                if (res.status === 200) {
                    if (res.data) {
                        setStartExamId(res.data._id)
                        if (res.data.examStart === true) {
                            await generateTokenForLiveExam(data, id2)
                        }
                        else {
                            navigate('/exam/instructions', { state: { examId: data, mappingId: id2 } })
                        }
                    }
                }
            })
            .catch((err) => {
                toast.error(err.response.data)
                console.error(err);
            })
    }

    function downloadCertificate(data: any, fileName: string) {
        const binaryString = window.atob(data.split(',')[1]);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
    }

    console.log(props.exams, "exma")

    return (
        <Row>
            <Col>
                <Card className="dcm-exam-list-card">
                    <Card.Header>
                        <div className='d-flex justify-content-start align-items-center'>
                            Exams Live
                            <div style={{ fontSize: "11px" }} className="ms-1">
                                <FontAwesomeIcon icon={faCircle} className="text-success " />
                            </div>
                        </div>


                    </Card.Header>
                    <Card.Body>
                        <Table borderless striped responsive className="">
                            <thead>
                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    {/* <th>
                                        Subject
                                    </th> */}
                                    <th className="text-center">Attempted</th>
                                    <th className="text-center">Approved</th>
                                    <th className="text-center text-nowrap">In-Review</th>
                                    <th className="text-center">Attempted But Unapproved</th>
                                    <th className="text-center">Invigilator Review</th>
                                    <th className="text-center">Student Review</th>
                                    <th className="text-center">Completed</th>
                                    <th className="text-center">Certificate</th>
                                    {/* <th>
                                        Status
                                    </th> */}
                                    <th>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.exams.length > 0 ?
                                        props.exams.map((data: any, index) => {
                                            if (!data.exam) {
                                                return (
                                                    <></>
                                                )
                                            }
                                            if (data) {
                                                console.log(data, "data")
                                                return (
                                                    <tr key={index} className=""  >
                                                        <td style={{ maxWidth: "250px" }}>
                                                            {data?.exam?.examName}
                                                        </td>
                                                        {/* <td className='fs-12'>
                                                    {data.exam.subject}
                                                </td> */}
                                                        <td className="font-monospace text-center">{data?.attemptedCount}</td>
                                                        <td className="font-monospace text-center">{data?.approvedCount}</td>
                                                        <td className="font-monospace text-center">{data?.inReviewCount}</td>
                                                        <td className="font-monospace text-center">{data?.attemptedButUnapprovedCount}</td>
                                                        {/* <td>
                                                    <BooleanLabel
                                                        value = {data.completionStatus}
                                                        label ={data.completionStatus ? "Completed" : "Pending"}
                                                    />
                                                    
                                                </td> */}

                                                        <td className="text-center">{data?.invigilatorSubmitForReview ? <CheckCircleOutlineOutlined className="text-success" /> : <FaRegCircleXmark className="text-danger" />}</td>
                                                        <td className="text-center">{data?.studentSubmitForReview ? <CheckCircleOutlineOutlined className="text-success" /> : <FaRegCircleXmark className="text-danger" />}</td>
                                                        <td className="text-center">{data?.completionStatus ? <CheckCircleOutlineOutlined className="text-success" /> : <FaRegCircleXmark className="text-danger" />}</td>
                                                        <td className='text-center'>
                                                            {/* {
                                                                data?.certificate ? 
                                                                <FaDownload style={{ cursor: "pointer" }} onClick={() => { downloadCertificate(data?.certificate, data?.exam?.examName) }} />
                                                                :
                                                                <span>Not Generated</span>
                                                            } */}
                                                            {data?.certificate ? <span className="text-success">Generated</span> : <span className="text-danger">Not Generated</span>}
                                                        </td>
                                                        <td>
                                                            {
                                                                data.completionStatus ? <Button disabled={true} size="sm" variant="secondary">
                                                                    Exam Completed
                                                                </Button> :
                                                                    data?.active ?
                                                                        <Button className="fs-10" variant="success" onClick={() => { startExam(data.exam._id, data._id) }} size="sm">Read Instructions & Start Exam <FontAwesomeIcon icon={faPlayCircle} /> </Button> :
                                                                        <Button className="fs-10" variant="warning" disabled size="sm">You are not allowed to start this exam. Please contact admin if this is wrong</Button>

                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        }) :
                                        <tr>
                                            <td colSpan={10} className="text-center" style={{ fontSize: "14px" }}>
                                                No Live Exams
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}